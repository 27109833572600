import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { InstaPost } from '../../constants';
import SocialPostsContainer from '../SocialPostsContainer';
import InstaPostRenderer from '../PostRenderers/InstaPostRenderer';
import { getPostAccountIcon } from '../../utils';

const maxCaptionCharCount = 150;

const truncateCaption = (caption: string) => {
  if (caption && caption.length > maxCaptionCharCount) {
    const truncationSuffix = '...';
    const substringAtMaxLength = caption.substr(
      0,
      maxCaptionCharCount - truncationSuffix.length
    );
    const substringAtFullWorld = substringAtMaxLength.substr(
      0,
      Math.min(
        substringAtMaxLength.length,
        substringAtMaxLength.lastIndexOf(' ')
      )
    );

    return substringAtFullWorld.concat(truncationSuffix);
  }

  return caption;
};

const InstaPostsMapper = (nodeParent): InstaPost => {
  const { id, caption, localFile, username, permalink, timestamp } =
    nodeParent.node;

  const image = getImage(localFile);
  const ImageComponent = <GatsbyImage image={image} alt="" />;

  const post = {
    ImageComponent,
    InstaAccountIcon: getPostAccountIcon(username),
    username,
    id,
    caption: truncateCaption(caption),
    timestamp,
    permalink,
  };

  return post;
};

const InstaSorterFunction = (a, b) => {
  const aDate = new Date(a.node.timestamp);
  const bDate = new Date(b.node.timestamp);

  return bDate.valueOf() - aDate.valueOf();
};

const PostsContainer: React.FC = () => {
  const { allInstagramContent } = useStaticQuery(graphql`
    query InstagramPosts {
      allInstagramContent {
        edges {
          node {
            id
            timestamp
            username
            permalink
            caption
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1)
              }
            }
          }
        }
      }
    }
  `);

  const allPostsFormattedAndSorted: InstaPost[] = allInstagramContent.edges
    .sort(InstaSorterFunction)
    .map(InstaPostsMapper);

  return (
    <SocialPostsContainer
      allPosts={allPostsFormattedAndSorted}
      PostsRenderer={InstaPostRenderer}
    />
  );
};

export default PostsContainer;
