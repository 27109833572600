import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InstaPostIcon from '../../images/latest/insta-post-icon.svg';
import { getPostClassName } from '../../utils';
import { InstaPost } from '../../constants';

const InstaIcon = (
  <img
    src={InstaPostIcon}
    className="latest-post-insta-icon"
    style={{ position: 'absolute' }}
    alt=""
  />
);

const PostComponent = ({
  ImageComponent,
  InstaAccountIcon,
  isSelectedImage,
  caption,
  username,
}: PostData) => (
  <div className="latest-post-img-container">
    {ImageComponent}
    {InstaIcon}
    <div className="latest-post-source-icon">{InstaAccountIcon}</div>
    {isSelectedImage && (
      <div className="insta-caption-background latest-caption-container">
        <div>{caption}</div>
        <div className="d-flex flex-row justify-content-between mt-auto">
          <div>{`@${username}`}</div>
          <div className="upper ">View Post &gt;</div>
        </div>
      </div>
    )}
  </div>
);

interface PostData {
  ImageComponent: JSX.Element;
  InstaAccountIcon: JSX.Element;
  username: string;
  caption: string;
  isSelectedImage: boolean;
}

interface IProps {
  posts: InstaPost[];
}

const PostsContent: React.FC<IProps> = ({ posts }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const enrichedPosts = posts.map((post, index) => {
    const isSelectedImage = selectedImage === index;

    return {
      ...post,
      isSelectedImage,
      setActiveImage: () => {
        setSelectedImage(index);
      },
      onClick: () => {
        if (isSelectedImage) {
          window.location.href = post.permalink;
        } else {
          setSelectedImage(index);
        }
      },
      unsetActiveImage: () => setSelectedImage(null),
    };
  });

  return (
    <>
      <Row className="d-none d-md-flex">
        {enrichedPosts.map((enrichedPost, index) => {
          const {
            id,
            ImageComponent,
            caption,
            username,
            permalink,
            InstaAccountIcon,
            isSelectedImage,
            setActiveImage,
            unsetActiveImage,
          } = enrichedPost;

          // This hides any end tiles which do not fill an entire row
          // (e.g. 7th image in a 2x grid, 10th & 11th images in a 3x grid)
          return (
            <Col
              className={`
                px-0 py-0
                ${getPostClassName(enrichedPosts.length, index)}
              `}
              key={id}
              md={6}
              lg={4}
              onFocus={setActiveImage}
              onMouseOver={setActiveImage}
              onMouseEnter={setActiveImage}
              onMouseLeave={unsetActiveImage}
            >
              <a
                style={{ display: 'block', height: '100%' }}
                href={permalink}
                target="_blank"
                rel="noreferrer"
              >
                <PostComponent
                  ImageComponent={ImageComponent}
                  InstaAccountIcon={InstaAccountIcon}
                  isSelectedImage={isSelectedImage}
                  caption={caption}
                  username={username}
                />
              </a>
            </Col>
          );
        })}
      </Row>

      <Row className="d-flex d-md-none">
        {enrichedPosts.map((enrichedPost) => {
          const {
            id,
            ImageComponent,
            caption,
            username,
            InstaAccountIcon,
            isSelectedImage,
            onClick,
          } = enrichedPost;

          return (
            <Col className="px-0 py-0" key={id} xs={12}>
              <PostComponent
                ImageComponent={ImageComponent}
                InstaAccountIcon={InstaAccountIcon}
                isSelectedImage={isSelectedImage}
                caption={caption}
                username={username}
              />
              <button
                type="button"
                className="latest-invisible-button"
                aria-label="Go to Instagram post"
                onClick={onClick}
              />
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default PostsContent;
