import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Banner from '../components/latest/Banner';
import { getPageTitle } from '../utils';
import PageWrapper from '../components/PageWrapper';
import { socialUrls } from '../constants';
import Posts from '../components/latest/Posts';
import DiscordIcon from '../images/latest/social/discord.svg';
import TwitterIcon from '../images/latest/social/twitter.svg';
import FacebookIcon from '../images/latest/social/facebook.svg';
import InstaIcon from '../images/latest/social/instagram.svg';
import LinkedinIcon from '../images/latest/social/linkedin.svg';
import RedditIcon from '../images/latest/social/reddit.svg';

const pageTitle = getPageTitle('Latest');

const leftMobileQuaterDesktop =
  'pl-0 px-md-0 pt-1 py-md-0 latest-social-img left-mobile-quater-desktop';
const rightMobileQuaterDesktop =
  'pr-0 px-md-0 pt-1 py-md-0 latest-social-img right-mobile-quater-desktop';
const fullMobileHalfDesktop = 'px-0 pt-1 py-md-0 latest-social-img';

const BlogPost = ({ post }) => (
  <a
    href={`${post.fields.slug}`}
    className="col-12 col-sm-6 col-lg-3 py-0 px-0 latest-blog-post-card"
  >
    <article className="full-height d-flex flex-column">
      <GatsbyImage
        image={post.frontmatter.coverImage.childImageSharp.gatsbyImageData}
        alt=""
      />
      <h2 className="mt-3">{post.frontmatter.title}</h2>
      <small className="mb-2">{post.frontmatter.date}</small>
      <div className="mt-auto">
        <p>{post.excerpt}</p>
      </div>
    </article>
  </a>
);

const LatestPage: React.FC = ({ data }) => (
  <PageWrapper>
    <Helmet>
      <title>{pageTitle}</title>
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content="Find out about us." />
    </Helmet>
    <Banner />
    <Container className="latest-container" fluid>
      <Posts />
      <Row>
        {data.blog.posts.map((post) => (
          <BlogPost post={post} key={post.id} />
        ))}
      </Row>
      <Row>
        <Col className={`${leftMobileQuaterDesktop} twitter`} xs={6} md={3}>
          <a href={socialUrls.twitter} target="_blank" rel="noreferrer">
            <img src={TwitterIcon} alt="Twitter" />
          </a>
        </Col>
        <Col className={`${rightMobileQuaterDesktop} facebook`} xs={6} md={3}>
          <a href={socialUrls.facebook} target="_blank" rel="noreferrer">
            <img src={FacebookIcon} alt="Facebook" />
          </a>
        </Col>
        <Col className={`${leftMobileQuaterDesktop} linkedin`} xs={6} md={3}>
          <a href={socialUrls.linkedin} target="_blank" rel="noreferrer">
            <img src={LinkedinIcon} alt="Linkedin" />
          </a>
        </Col>
        <Col className={`${rightMobileQuaterDesktop} insta`} xs={6} md={3}>
          <a href={socialUrls.instagram} target="_blank" rel="noreferrer">
            <img src={InstaIcon} alt="Instagram" />
          </a>
        </Col>
      </Row>
      <Row>
        <Col className={`${fullMobileHalfDesktop} reddit`} xs={12} md={6}>
          <a href={socialUrls.reddit} target="_blank" rel="noreferrer">
            <img src={RedditIcon} alt="Reddit" />
          </a>
        </Col>
        <Col className={`${fullMobileHalfDesktop} discord`} xs={12} md={6}>
          <a href={socialUrls.discord} target="_blank" rel="noreferrer">
            <img src={DiscordIcon} alt="Discord" />
          </a>
        </Col>
      </Row>
    </Container>
  </PageWrapper>
);

export const pageQuery = graphql`
  query MyQueryGetPostsLatest {
    blog: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 4
    ) {
      posts: nodes {
        frontmatter {
          date(fromNow: true)
          title
          coverImage {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                aspectRatio: 1.777777777777777777
              )
            }
          }
        }
        fields {
          slug
        }
        excerpt
        id
      }
    }
  }
`;

export default LatestPage;
