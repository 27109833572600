import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';

import { socialUrls } from '../../constants';

const LatestBanner: React.FC = () => {
  const { bannerImg } = useStaticQuery(
    graphql`
      query {
        bannerImg: file(relativePath: { eq: "latest/banner/banner.png" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `
  );
  const backgroundImg = getImage(bannerImg);

  return (
    <BgImage image={backgroundImg}>
      <Container fluid>
        <Row>
          <Col>
            <Container>
              <Row>
                <Col className="latest-img-text">
                  <h3 className="upper very-bold">Latest</h3>
                  <p>
                    Be the first to see the latest news and updates here and on
                    our socials.
                  </p>
                  <a
                    href={socialUrls.linktree}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      variant="secondary"
                      className="px-lg-3 py-3 very-bold"
                    >
                      View Our Socials
                    </Button>
                  </a>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </BgImage>
  );
};

export default LatestBanner;
